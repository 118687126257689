export function bootstrap() {
  // @ts-expect-error

  BigInt.prototype.toJSON = function () {
    return this.toString();
  };

  // HACK: Polyfill crypto which is required by chakra-multiselect
  // global.crypto = global.crypto ?? require('crypto').webcrypto;
}
